import React from "react";

const TermsContent = () => {
    return (
        <div className="content">
            <p className="bold">
                ABOUT 'SCALES NFT PROJECT'
            </p>
            <p>
                The Scales NFT Project ('Scales') is a non-fungible token ('NFT')  project 
                built on the Ethereum blockchain. It will comprise a total of 10,000 NFT tokens 
                and anyone can mint the NFTs using an Ethereum blockchain compatible wallet. 
                <br/>
                Please note: The Scales team reserves the right to terminate or end the initial sale of the project at any time. 
            </p>
            <p>
                The terms and conditions ('T&C') set out herein including inter alia offers the Collectors 
                and subsequent Collectors to own the NFT token(s). The Collector shall read the T&C carefully 
                as it contains important information about his/her legal rights, remedies and obligations. 
                By minting/owning our NFT tokens, you agree to comply with and be bound by these T&C. 
                These T&C along with Smart Contract constitutes a legally binding agreement between the Collector and the team. 
                <br/>
                The Collector hereby confirms that he/she understands and agrees to comply with these T&C, 
                together with any documents that may be referred to and are incorporated by reference herein. 
            </p>
            <p>
                <span className="bold">
                    1.	 DEFINITION(S)
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">1.1.</span>&nbsp;&nbsp; 
                    'Art' means any art, design, paintings and drawings and the likes that may be associated with Purchased NFT;
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">1.2.</span>&nbsp;&nbsp; 
                    'Buyer or Collector' means anyone rightfully owning the NFT or Art and include all s
                    ubsequent Collectors or Buyers; The term Collector or Buyer is used interchangeably; 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">1.3.</span>&nbsp;&nbsp; 
                    'NFT' means any blockchain-tracked non-fungible token that certifies 
                    a digital asset to be unique and therefore not interchangeable; 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">1.4.</span>&nbsp;&nbsp; 
                    'NFT' means NFT collectible art, created and operated by the Scales NFT Project team; 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">1.5.</span>&nbsp;&nbsp; 
                    'Own' means Collector has purchased or otherwise rightfully acquired NFT from a legitimate source, 
                    where proof of such purchase is recorded on the relevant blockchain;
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">1.6.</span>&nbsp;&nbsp; 
                    ‘Purchased NFT’ means NFT that the Collector rightfully owns;
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">1.7.</span>&nbsp;&nbsp; 
                    ‘Smart Contract’ means a self-executing contract with the terms of the agreement between Buyer and 
                    seller being directly written into lines of code. The code and the agreements contained therein exist 
                    across a distributed, decentralized blockchain network (in our case, the Ethereum Network). 
                    The code controls the execution, and transactions are trackable and irreversible. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">1.8.</span>&nbsp;&nbsp; 
                    ‘Painting’ means a complete and named composition, comprised of a grid of adjacent NFTs.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">1.9.</span>&nbsp;&nbsp; 
                    ‘Period’ means the length of time between 12PM UST on the first calendar day of any month and 12PM UST 
                    on the first calendar day of the subsequent month, when snapshots of the blockchain are taken.
                </span>
            </p>
            <p>
                <span className="bold">
                    2.	 OWNERSHIP 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">2.1.</span>&nbsp;&nbsp; 
                    ‘The Collector Owns the NFT. The ownership of the NFT is governed entirely by Smart Contracts on the Ethereum Network. 
                    Smart Contracts cannot be reversed or modified in any way whatsoever. We promise that at no point we may seize, freeze, 
                    or otherwise modify the ownership of any NFT.  
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">2.2.</span>&nbsp;&nbsp; 
                    Subject to the continued compliance with these T&C, we grant the Collector a worldwide non-exclusive and 
                    non-transferable right (licensed) to use, copy, and display the Purchased NFT(s), solely for the following purposes: 
                </span>
                <p>
                    (i) for Collector’s personal use;  
                </p>
                <p>
                    (ii) commercial use subject to the Smart Contract. The Purchased NFT may be used as Art for the purpose of 
                    commercializing merchandise that includes, contains, or consists of the Art associated with the Purchased NFT(s). 
                    The license granted applies only to the extent that the Collector continues to Own the applicable Purchased NFT(s). 
                    If at any time Collector sells, trade, donate, give away, transfer, or otherwise dispose of the Purchased NFT(s) for any reason, 
                    the license granted in this clause will immediately expire without the requirement of notice, and the Collector will 
                    have no further rights in or to the Art for those Purchased NFT(s).  
                    <br/>
                    provided that such commercial use does not 
                    (a) include any form of collaboration or involvement of any brand or other third party; 
                    (b) apply or extend if the Collector’s earning is more than USD 100,000 in gross revenue in a given calendar year. 
                    In the event Commercial Use exceeds USD 100,000 in a given year then the Collector needs to enter into a commercial license with Scales team. 
                    If the Collector exceeds USD 100,000 limitation and does not execute a new commercial license then the Collector will 
                    be in breach of these T&C and Smart Contract. The Collector must contact Scales team regarding entering into 
                    a new commercial license agreement (which will be at Scales team’s sole and absolute discretion) when the Collector exceeds USD 100,000 limit. 
                    Without entering into a commercial license with Scales team, the Collector acknowledges and agrees that (I) the Collector 
                    is in breach of these T&C and Smart Contract; (II) in addition to any remedies that may be available to Scales team at law or in equity, 
                    the license shall be terminated immediately; and (III) the Collector will be liable and responsible to reimburse Scales team any unjust 
                    enrichment and any costs and expenses incurred by Scales team during the course of enforcing these T&C and Smart Contracts against the Collector 
                    (including attorney and legal fees);  
                </p>
                <p>
                    (iii) as part of a marketplace that permits the purchase and sale of Purchased NFTs.
                    <br/>
                    provided that the marketplace cryptographically verifies each NFT owner’s rights to display the art 
                    for their Purchased NFT(s) to ensure that only the actual owner can display the NFT; and 
                </p>
                <p>
                    (iv) as part of a third-party website or application that permits the inclusion, involvement, or participation of Collector’s NFT(s).  
                    <br/>
                    provided that the website/application cryptographically verifies each NFT owner’s rights to display their Purchased NFTs 
                    and to ensure that only the actual owner can display the NFT.  
                    <br/>
                    provided further that the NFT is no longer visible once the owner of the Purchased NFT leaves the website/application 
                </p>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">2.3.</span>&nbsp;&nbsp; 
                    The Collector acknowledges and agrees that Scales team owns all legal rights, 
                    title and interest in and to the NFT, the Art, Name and Likeness, and all intellectual property rights therein. 
                    The license that the Collector has in and to the NFT and the Art are limited to those expressly stated hereinabove. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">2.4.</span>&nbsp;&nbsp; 
                    The Collector acknowledges and agrees that on a resale of NFT or sale of Art, 
                    the Collector will bring notice of these T&C and Smart Contracts to the Buyer(s). 
                    Any damage or harm caused due to the failure of revealing or notifying these T&C 
                    and Smart Contracts to the Buyer will be indemnified by the Collector. 
                </span>
            </p>
            <p>
                <span className="bold">
                    3. MINTING AND GAS FEES 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">3.1.</span>&nbsp;&nbsp; 
                    The Collector transactions regarding NFT(s) including minting, tokenizing, purchasing, or confirming, 
                    are facilitated by Smart Contracts existing on the Ethereum network. The first minting of NFTs via 
                    &nbsp;<a href="https://ScalesNFT.com/" target="_blank">https://ScalesNFT.com/</a>&nbsp; is offered free of cost to mint. 
                    Further, the platform requires the payment of a transaction fee (‘Gas Fees’) for every transaction that occurs on the Ethereum network, 
                    and such Gas Fees will be paid by the Collector. The Gas Fees fund the network of computers that run the decentralized Ethereum network. 
                    The Collector will need to pay Gas Fees for each transaction.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">3.2.</span>&nbsp;&nbsp; 
                    The value of the Gas Fee changes, often unpredictably, and is entirely outside of the control of Scales team.  
                    The Collector acknowledges that under no circumstances will a contract, agreement, offer, sale, bid, 
                    or other transaction regarding NFT(s) be invalidated, revocable, retractable, or otherwise unenforceable 
                    on the basis that the Gas Fees for the given transaction was unknown, too high, or otherwise unacceptable to the Collector. 
                    The Collector also acknowledges and agrees that Gas Fees are non-refundable under all circumstances.  
                </span>
            </p>
            <p>
                <span className="bold">
                    4.	 DISCLAIMER
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.1.</span>&nbsp;&nbsp; 
                    The Collector expressly understands and agrees that access to and use of the site/platform is at his/her sole risk, 
                    and that the site/platform is provided ‘as in’ and as ‘available without warranties of any kind, whether express or implied.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.2.</span>&nbsp;&nbsp; 
                    The Scales team will not be responsible or liable to the Collector for any loss of any kind that 
                    the Collector may incur as a result of using the site/platform, the Ethereum blockchain network, 
                    or the electronic wallet, including but not limited to any losses, damages or claims arising from 
                    (i) the Collector's error, such as forgotten passwords or incorrectly construed Smart Contracts or other transactions; 
                    (ii) server failure or data loss; (iii) corrupted wallet files; or (iv) unauthorized access or activities by third parties, including, 
                    but not limited to, the use of viruses, phishing, brute-forcing or other means of attack against the site/platform. 
                    The Scales team will not be responsible or liable to the Collector for any loss of any kind from any action 
                    that results from providing an incorrect wallet address.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.3.</span>&nbsp;&nbsp; 
                    To the fullest extent permissible under applicable law, we make no express warranties and hereby disclaim all implied warranties regarding the site 
                    and any part of it (including, without limitation, the site, any Smart Contracts, or any external websites or any platform). 
                    Including the implied warranties of merchantability, fitness for a particular purpose, non-infringement, correctness, accuracy, or reliability.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.4.</span>&nbsp;&nbsp; 
                    The Collectors hereby acknowledge and assume the risk of initiating, interacting with, participating in site/platform transactions and 
                    take full responsibility and liability for the outcome of any transaction they initiate, whether or not the Smart Contracts, the website, 
                    or other platform features behave as expected or intended. The Collector hereby represents that they are knowledgeable, experienced 
                    and sophisticated in using blockchain technology, the site/platform and in initiating Ethereum-based transactions. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.5.</span>&nbsp;&nbsp; 
                    Certain transactions on the site/platform, including but not limited to primary sales, 
                    secondary market sales, listings, offers, bids, acceptances, and other operations through 
                    the site/platform utilize experimental Smart Contracts and blockchain technology, 
                    including non-fungible tokens, cryptocurrencies, consensus algorithms, and decentralized or peer-to-peer networks and systems. 
                    The Collector acknowledges and agrees that such technologies are experimental, speculative, 
                    and inherently risky. The Collector acknowledges and agrees that the NFT(s) may be subject 
                    to bugs, malfunctions, timing errors, hacking and theft, or changes to the protocol rules 
                    of the blockchain, which can adversely affect the Smart Contracts and may expose you to 
                    a risk of total loss, forfeiture of your digital currency or NFTs, or lost opportunities 
                    to buy or sell NFTs. We assume no liability or responsibility for any such Smart Contracts 
                    or related failures, risks, or uncertainties. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.6.</span>&nbsp;&nbsp; 
                    Without limiting the generality of the foregoing, we, our subsidiaries, affiliates, and licensors 
                    do not represent or warrant to the Collector that (i) access to or use of the site/platform will 
                    meet the Collector’s requirements; (ii) access to or use of the site/platform will be uninterrupted, 
                    timely, secure or free from error; (iii) usage data provided through the site/platform will be accurate; 
                    (iv) personal injury or property damage, of any nature whatsoever, resulting from your access to and use 
                    of the site/platform will not occur; (v) the site/platform or any content, services, or features made 
                    available on or through the site/platform are free of viruses or other harmful components; and 
                    (vi) that any data disclosed when the Collector use the site/platform will be secure. Provided that some 
                    jurisdictions do not allow the exclusion of implied warranties in contracts with consumers, so some or 
                    all of the above exclusions may not apply to the Collector. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.7.</span>&nbsp;&nbsp; 
                    The Collector should be aware that the prices of NFT(s) are extremely volatile and fluctuations 
                    in the prices of other NFTs and impact the price of your NFT both positively and negatively. The Collector assumes all risks.  
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.8.</span>&nbsp;&nbsp; 
                    Any financial transactions related to the NFTs will be conducted through the Ethereum network.
                     We will have no insight into or control over these payments or transactions, nor do we have 
                     the ability to reverse any transactions.  We have no liability to the Collector or to any third 
                     party for any claims or damages that may arise because of transactions that the Collector engages 
                     in or any other transactions that the Collector conducts via the Ethereum network. We are not be 
                     responsible or liable to the Collector for any losses that incur as the result of the use of the 
                     Ethereum network, nor do we have control over or make any guarantees regarding any Smart Contracts. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.9.</span>&nbsp;&nbsp; 
                    We are not responsible for losses due to blockchains or any other features of the blockchain network, 
                    or any electronic wallet, including but not limited to late reporting by developers or representatives 
                    (or no reporting at all) of any issues with the blockchain network, including forks, technical node issues, 
                    or any other issues resulting in fund losses.  
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.10.</span>&nbsp;&nbsp; 
                    The Collector accepts the inherent security risks of providing information over the internet, 
                    and agrees that we have no liability or responsibility for any breach of security. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.11.</span>&nbsp;&nbsp; 
                    The Collectors are forbidden from engaging in any attack, hack, denial-of-service attack, interference, 
                    or exploit of Smart Contracts. The operations performed by the Collector that are permitted 
                    by Smart Contracts may nevertheless be a violation of our T&C. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">4.12.</span>&nbsp;&nbsp; 
                    NFT(s) are intangible digital assets that exist only by virtue of the ownership record maintained in the blockchain network.
                     All Smart Contracts, including any transfer of title that might occur in any unique NFT, are conducted, 
                     and occur on the decentralized ledger within the blockchain network. We have no control over and 
                     make no guarantees or promises with respect to Smart Contracts.
                </span>
            </p>
            <p>
                <span className="bold">
                    5.	 RISK ASSUMPTION 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">5.1.</span>&nbsp;&nbsp; 
                    Ownership of NFT confers ownership as per the above-stated clauses. Accordingly, 
                    no information on any site/platform (or any other documents mentioned therein) is 
                    or may be considered to be advice or an invitation to enter into an agreement for 
                    any investment purpose. Further, nothing on any site/platform qualifies or is intended 
                    to be an offering of securities in any jurisdiction nor does it constitute an offer or 
                    an invitation to purchase shares, securities, or other financial products. Due to the 
                    artistic nature of the project, Scales has not been registered with or approved by any 
                    regulator in any jurisdiction. It remains your sole responsibility to assure that the 
                    purchase of the Scales and the associated art complies with laws and regulations in your jurisdiction. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">5.2.</span>&nbsp;&nbsp; 
                    The Collector assumes all risks associated with using an internet-based currency, 
                    including, but not limited to, the risk of hardware, software and internet connections, 
                    the risk of malicious software introduction, and the risk that third parties may obtain 
                    unauthorized access to information stored within your wallet.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">5.3.</span>&nbsp;&nbsp; 
                    NFTs, cryptocurrencies and blockchain technology are relatively new and the regulatory landscape is unsettled.  
                    New regulations could negatively impact such technologies impacting the value of NFT(s). 
                    You understand and accept all risks in that regard.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">5.4.</span>&nbsp;&nbsp; 
                    The Collector assumes all responsibility for any adverse effects of disruptions or 
                    other issues impacting Ethereum or the Ethereum platform or Ethereum blockchain. 
                </span>
            </p>
            <p>
                <span className="bold">
                    6.	 ROYALTY FEES AND TAXES 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">6.1.</span>&nbsp;&nbsp; 
                    The NFT(s) are subject to royalty fees on resale (first transfer of ownership from the Collector) 
                    or any subsequent resales of the NFT (i.e. any sales after the first transfer of ownership from the Collector). 
                    On any resale or subsequent resale of the NFT, the successful bidder and Buyer of the NFT may be obligated, 
                    on the seller’s behalf, to collect and remit to the Scales team, royalty fees equal to a percentage of the 
                    resale price of the NFT. The resale royalty percentage will be identified in the Smart Contracts relating to the NFT.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">6.2.</span>&nbsp;&nbsp; 
                    The royalty fees shall be on ‘commercial use as per the terms of this agreement’.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">6.3.</span>&nbsp;&nbsp; 
                    The royalty fees will be on the transaction amount excluding transaction fees, taxes, duties imposed by law 
                    or any other amount in relation to such resales and subsequent resales. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">6.4.</span>&nbsp;&nbsp; 
                    The Collector agrees that he/she will be solely responsible for (i) determining what, 
                    if any, taxes apply to NFT transactions and (ii) paying any and all sales, use, value-added 
                    and other taxes, duties, and assessments (except taxes on our net income) or hereafter claimed 
                    or imposed by any governmental authority associated with the use of, and transactions conducted 
                    in relation to the Purchased NFT including, without limitation, any taxes that may become 
                    payable as the result of your ownership, transfer, purchase, or sale of any works. 
                    Neither the Scales team nor its affiliates or employees are responsible for determining 
                    the taxes that may apply to NFT transactions. Except for income taxes levied on us, the Collector 
                    (a) will pay or reimburse us for all national, federal, state, local or other taxes and assessments 
                    of any jurisdiction, including value-added taxes and taxes as required by international tax treaties, 
                    customs or other import or export taxes, and amounts levied in lieu thereof based on charges set, 
                    services performed or payments made hereunder, as are now or hereafter may be imposed under 
                    the authority of any national, state, local or any other taxing jurisdiction; and (b) will not be 
                    entitled to deduct the amount of any such Taxes from payments (including service fees) made to us pursuant to these T&C.
                </span>
            </p>
            <p>
                <span className="bold">
                    7.	 ROYALTY CLUB
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">7.1.</span>&nbsp;&nbsp; 
                    Collectors of any set of four adjacent NFTs in a single Painting are in the Royalty Club 
                    and are eligible to receive a portion of royalty fees from a pool set up for this purpose.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">7.2.</span>&nbsp;&nbsp; 
                    For the purpose of determining whether a Collector is eligible for royalty payments in any Period,
                     a snapshot of the blockchain is taken at 12PM UST on the first day of each month. Collectors 
                     who Own an adjacent set of four NFTs when the snapshot is taken, qualify to claim royalties 
                     from the entire previous month.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">7.3.</span>&nbsp;&nbsp; 
                    The Scales team receives all royalty distributions from several third-party marketplaces 
                    and cannot influence the timing or punctual receipt of royalty payments owed from these sources. 
                    The Collector represents and warrants that he/she understands that the royalty pool may 
                    not accurately account for all royalties paid on secondary sales within any Period. 
                    The royalty pool and royalty shares are based solely on royalties received by the Scales team within that Period.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">7.4.</span>&nbsp;&nbsp; 
                    The Scales team is not responsible for notifying any Collector of Royalty Club status or available 
                    royalty funds at any time. The Collector agrees to check the Royalty Club portal on this website 
                    (<a href="https://scalesnft.com/portal" target="_blank">https://scalesnft.com/portal</a>)  
                    frequently for status and available shares.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">7.5.</span>&nbsp;&nbsp; 
                    The Collector agrees to claim/withdraw their royalty share(s) every month using the Royalty Club portal on this website 
                    (<a href="https://scalesnft.com/portal" target="_blank">https://scalesnft.com/portal</a>). 
                    Royalty share(s) can be withdrawn between 12PM UST on the first of each month, 
                    and 12PM UST on the first of the next month. Shares left unclaimed after the cut-off are forfeited and rolled 
                    over into the next month’s pool, to be divided among all club members.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">7.6.</span>&nbsp;&nbsp; 
                    The Scales team may change the percentage of royalties allocated to the pool, 
                    impose additional restrictions or qualifications for Collectors to be eligible for 
                    royalty shares, or terminate the Royalty Club program and inform the Collector of 
                    such changes in writing on this website (<a href="https://scalesnft.com" target="_blank">https://scalesnft.com</a>)
                </span>                
            </p>
            <p>
                <span className="bold">
                    8.	 RESTRICTIONS
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">8.1.</span>&nbsp;&nbsp; 
                    The Collector agrees that they may not permit any third party to do or attempt 
                    to do any of the foregoing without Scales team’s express prior written consent in each case: 
                    <br/>
                    (i) use the NFT(s) or the Art in connection with images, videos, or other forms of media 
                    that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably 
                    be found to constitute hate speech or otherwise infringe upon the rights of others; and   
                    <br/>
                    (ii) sell, distribute for commercial gain (including, without limitation, giving away in the hopes of eventual commercial gain), 
                    or otherwise commercialize merchandise that includes, contains, or consists of the NFT(s) 
                    or the Art except as expressly permitted in these T&C and Smart Contracts.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">8.2.</span>&nbsp;&nbsp; 
                    The Scales team may impose additional restrictions on the Collector's ability to use the NFT(s) 
                    or the Art and inform the Collector of such additional restrictions in writing (email is acceptable), 
                    the Collector will be responsible for complying with all such restrictions from the date that 
                    he/she receives the notice, and that failure to do so will be deemed a breach of this license.
                </span>
            </p>
            <p>
                <span className="bold">
                    9.	 REPRESENTATION AND WARRANTIES  
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">9.1.</span>&nbsp;&nbsp; 
                    The Collector represents and warrants that he/she is at least 18 years of age 
                    (or the minimum age required in his/her jurisdiction to enter into this agreement). 
                    The Collector has the full right, power, and authority to agree and enter into these T&C and Smart Contracts. 
                    <br/>
                    OR 
                    <br/>
                    The Collector affirms that he/she is over the age of 13, as Scales is not intended for children under 13. 
                    If you are 13 or older but under the age of 18, or the legal age of majority where you reside if that jurisdiction
                     has an older age of majority, then you agree to review these terms with your parent or guardian to make sure 
                     that both you and your parent or guardian understand and agree to these terms. The Collector agrees to 
                     have his/her parent or guardian review and accept these terms on his/her behalf. If you are a parent or 
                     guardian agreeing to the terms for the benefit of a child over 13, then you agree to and accept full 
                     responsibility for that child's conduct, including all financial charges and legal liability that he/she may incur. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">9.2.</span>&nbsp;&nbsp; 
                    The Collector agrees to use any NFT(s) and Art only for purposes that are legal, 
                    proper and in accordance with these T&C, Smart Contracts and any applicable laws or regulations. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">9.3.</span>&nbsp;&nbsp; 
                    The Collector represents and warrants that he/she understands the cryptocurrencies 
                    and the NFT market, along with associated risks, expenses, and fees.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">9.4.</span>&nbsp;&nbsp; 
                    All transactions of Art and NFTs are initiated through one or more Smart Contracts 
                    or any other Smart Contracts at the sole discretion of the Scales team. 
                    The Smart Contracts are configured to facilitate the execution of a sale or transfer of any NFT(s). 
                    The Collector acknowledges the risk of Smart Contracts and agrees to be bound by the outcome of 
                    any Smart Contracts operation by invoking, calling, requesting, or otherwise engaging with the Smart Contracts, 
                    whether or not the Smart Contracts behave as per the Collector’s expectations.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">9.5.</span>&nbsp;&nbsp; 
                    The Collector acknowledges and agrees that there are risks associated with purchasing and holding any NFT(s) 
                    and using blockchain technology. These include, but are not limited to, risk of losing access to any NFT(s) 
                    due to loss of private key(s), custodial error or purchaser error, risk of mining or blockchain attacks, 
                    risk of hacking and security weaknesses, risk of unfavorable regulatory intervention in one or more jurisdictions, 
                    risks related to token taxation, risk of personal information disclosure, risk of uninsured losses, unanticipated risks, 
                    and volatility risks.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">9.6.</span>&nbsp;&nbsp; 
                    The Collector is entirely responsible for the safety and management of his/her own private Ethereum wallets.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">9.7.</span>&nbsp;&nbsp; 
                    The Collector acknowledges and agrees that if he/she engages in any of the activities prohibited by 
                    the law/regulation or these T&C or Smart Contracts, the Scales team may, at our sole and absolute discretion, 
                    without notice to the Collector, and without limiting any of our other rights or remedies at law or in equity, 
                    immediately suspend or terminate your license. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">9.8.</span>&nbsp;&nbsp; 
                    Scales team makes no representation and provides no warranty whatsoever in relation to the nature, description, 
                    uniqueness, functionality, operation, use, fitness, utility, compatibility, or interoperability of the digital 
                    file comprising any NFT or Artwork, with digital platforms and/or software either now or in the future.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">9.9.</span>&nbsp;&nbsp; 
                    The Scales team does not warrant, endorse, guarantee, or assume responsibility for any service advertised or 
                    offered by a third-party through the website or any hyperlink or featured in any banner or other advertising 
                    and the Scales team will not be a party to, or in any way be responsible for, monitoring any transaction 
                    between you and third-party providers of products or services, other than as provided by the authorized 
                    site/platform. As with the transaction of a product or service through any medium or in any environment, 
                    the Collector should use his/her best judgment and exercise caution where appropriate.
                </span>
            </p>
            <p>
                <span className="bold">
                    10.	 LIMITATION TO LIABILITY 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">10.1.</span>&nbsp;&nbsp; 
                    We disclaim all liability in connection with the purchase of any NFT(s). 
                    It is the Collector’s responsibility to ensure that the purchase of any NFT or the Art has been completed.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">10.2.</span>&nbsp;&nbsp; 
                    The Collector understands and agrees that we will not be liable to you or to any third party for any indirect, 
                    incidental, special, consequential, or exemplary damages which you may incur, howsoever caused and under any 
                    theory of liability, including, without limitation, any loss of profits (whether incurred directly or indirectly), 
                    loss of goodwill or business reputation, loss of data, cost of procurement of substitute goods or services, 
                    or any other intangible loss, even if we have been advised of the possibility of such damages.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">10.3.</span>&nbsp;&nbsp; 
                    The Collector agrees that our total, aggregate liability to you for any and all claims arising out of 
                    or relating to these T&C and Smart Contracts whether in contract, tort, strict liability, 
                    or any other legal theory, is limited to (a) the amounts you actually paid us under these terms 
                    in the twelve months preceding the date the claim arose.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">10.4.</span>&nbsp;&nbsp; 
                    Scales team is not liable for the outcome of any marketplace transaction. 
                </span>
            </p>
            <p>
                <span className="bold">
                    11.	 INDEMNIFICATION 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">11.1.</span>&nbsp;&nbsp; 
                    The Collector agrees to defend, hold harmless and indemnify the Scales team, 
                    including its subsidiaries, affiliates, officers, agents, employees, advertisers, 
                    licensors, suppliers or partners from and against any claim, liability, loss, damage 
                    (actual and consequential) of any kind or nature, suit, judgment, litigation cost, 
                    and reasonable attorneys' relating to or arising out of your license, sale or possession 
                    of the Licensed NFT(s) and/or the Collector’s participation in the offering, including 
                    (i) breach of this T&C or Smart Contracts or the documents it incorporates by reference;
                    (ii) violation of any law or the rights of a third party as a result of the Collector’s own interaction with such third party; 
                    (iii) any allegation that during the course of the license, the Collector infringes or otherwise violate the copyright, 
                    trademark, trade secret or other intellectual property or other rights of any third party; and/or 
                    (iv) any other activities in connection with the offering or the Licensed NFT(s) or Art. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">11.2.</span>&nbsp;&nbsp; 
                    This indemnity shall be applicable without regard to the negligence of any party, including any indemnified person. 
                </span>
            </p>
            <p>
                <span className="bold">
                    12.	SEVERABILITY  
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">12.1.</span>&nbsp;&nbsp; 
                    If any clause or sub-clause of these T&C is found by any court or administrative body of 
                    competent jurisdiction to be invalid or unenforceable, the invalidity or unenforceability 
                    of such provision shall not affect the other clauses or sub-clauses of these T&C or invalidate 
                    or render unenforceable such clauses or sub-clauses in other jurisdiction, and all clauses .
                    or sub-clauses not affected by such invalidity or unenforceability shall remain in full force and effect.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">12.2.</span>&nbsp;&nbsp; 
                    All clauses or sub-clauses not affected by such invalidity or unenforceability will be interpreted 
                    to accomplish the objectives of the clauses or sub-clauses to the greatest extent possible under any applicable laws.
                </span>
            </p>
            <p>
                <span className="bold">
                    13.	 WAIVERS 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">13.1.</span>&nbsp;&nbsp; 
                    Scales team’s failure or delay to exercise or enforce any right or provision 
                    of these T&C will not constitute or be deemed a waiver of the future exercise 
                    or enforcement of such right or provision. The waiver of any right or provision 
                    of these T&C will be effective only if in writing and signed for and on behalf 
                    of us by a duly authorized representative
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">13.2.</span>&nbsp;&nbsp; 
                    No single or partial exercise of any right or remedy will preclude any other or future exercise of any right or remedy. 
                </span>
            </p>
            <p>
                <span className="bold">
                    14.	 MISCELLANEOUS  
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">14.1.</span>&nbsp;&nbsp; 
                    The Collector is responsible for complying with all trade regulations and both 
                    foreign and domestic laws as it relates to his/her while dealing with any NFT(s). 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">14.2.</span>&nbsp;&nbsp; 
                    We may require the Collector to provide information and documents at the request of 
                    any competent authority or in case of application of any applicable law or regulation, 
                    including laws related to anti-laundering (legalization) of incomes obtained by criminal means, 
                    or for counteracting financing of terrorism. We may also require you to provide information and 
                    documents in cases where there is a reasonable requirement of such information and documents. 
                    In cases of failure to provide information and documents, the Scales team, in its sole discretion, 
                    may pause or cancel your license/ownership until such additional information and documents are 
                    reviewed by us and accepted as satisfying the requirements of applicable law. 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">14.3.</span>&nbsp;&nbsp; 
                    Scales team reserves the right to change or modify these T&C and Smart Contracts 
                    at any time and at our sole discretion, such changes will be duly intimated. 
                    Any changes to or modifications of these T&C and Smart Contracts will 
                    be in effect as of the date such changes are brought.
                </span>
            </p>
            <p>
                <span className="bold">
                    15.	 GOVERNING LAW AND JURISDICTION 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">15.1.</span>&nbsp;&nbsp; 
                    These T&C and Smart Contracts are governed by and shall be construed in accordance with 
                    the laws of the State of California, U.S. without regard to any choice or conflict of laws rules.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">15.2.</span>&nbsp;&nbsp; 
                    Any action seeking legal or equitable relief arising out of or relating to this agreement 
                    will be brought only in the courts of state or federal courts in the State of California. 
                </span>
            </p>
            <p>
                <span className="bold">
                    16.	 DISPUTE RESOLUTION 
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">15.1.</span>&nbsp;&nbsp; 
                    In the event of any controversy or claim arising out of or relating to these T&C 
                    or Smart Contracts, or a breach thereof, the parties hereto shall first attempt 
                    to settle the dispute by negotiation. If a settlement is not reached within sixty 
                    days after service of a written demand for negotiation, any unresolved controversy 
                    or claim shall be settled by arbitration administered by the American Arbitration Association.
                </span>
                <br/>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">15.2.</span>&nbsp;&nbsp; 
                    All disputes arising out of or in connection with these T&C or Smart Contracts, 
                    will be referred to and finally resolved by arbitration under the rules of the 
                    American Arbitration Association. The case will be adjudicated by a single 
                    arbitrator and will be administered by the American Arbitration Association in 
                    accordance with its applicable rules. Each party will cover its own fees and 
                    costs associated with the arbitration proceedings, which may be allowed as cost by the arbitrator. 
                    The place of arbitration will be California and State of California law shall apply. 
                    The award of the arbitrator will be final and binding, and any judgment on the award 
                    rendered by the arbitrator may be entered in any court of competent jurisdiction. 
                </span>
            </p>
            <p>
                <span className="bold">
                    17.	ENTIRE AGREEMENT
                </span>
                <br/>
                <span>
                    These terms constitute the entire agreement between you and us with respect to the subject matter hereof. 
                    This Agreement supersedes any and all prior or contemporaneous written and oral agreements, communications 
                    and other understandings (if any) relating to the subject matter of the Terms.
                </span>
            </p>
        </div>
    )
}

export default TermsContent