import React, {useState} from "react";
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import "./index.css";

const CollapsibleMenu = () => {

  const pages = ['About', 'Royalty Club', 'Vision', 'Team', 'Roadmap', 'FAQ', 'Contact'];
  const pageId = ['about', 'royalty_club', 'vision', 'team', 'roadmap', 'faq', 'contact']
  const [clicked, setClicked] = useState(false);

  const menuButtonClickHandler = () => {
    setClicked(!clicked);
  }

  const MenuItemClicked = () => {
      setClicked(!clicked);
  }

    return (
        <div className="nav-menu-narrow-wrap">
            <div className={"nav-menu-narrow"+(clicked ? " open-menu" : " close-menu")}>
                <div className="nav-menu-narrow-icon">
                    <Button 
                        onClick={menuButtonClickHandler}
                        >
                        <MenuIcon className="menu-icon"/>
                    </Button>                      
                </div>
                <div className="nav-menu-narrow-content">
                    {pages.map((page, index) => (
                    <Button
                        key={pageId[index]}
                        className="color-black navbar-item narrow-item"
                        href={'#'+pageId[index]}
                        style={{textTransform: 'capitalize'}}
                        onClick={MenuItemClicked}
                    >
                        {page}
                    </Button>
                    ))}
                </div>
            </div>
        </div>
        
    )
}

export default CollapsibleMenu
