import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import "./index.css"

const Vision = () => {
    return (
        <div className="vision" id="vision">
            <SectionHeader
                section={`Our Vision`}
                description={"We see with our heart."}
            />
            <div className="vision-content content">
                <div className="famous-phrase">
                    “If all the world is a commodity, how poor we grow. When all the world is a gift in motion, 
                    how wealthy we become.” - Robin Wall Kimmerer, Braiding Sweetgrass
                </div>
                <div>
                    <p className="bold">
                        All Scales NFTs are free to mint, excepting the cost of gas.
                    </p>
                    <p>
                        This project is an experiment exploring radical self-expression and radical generosity. 
                         We want to see what happens when we practice giving without restraint.
                    </p>
                    <p>
                        The founders of this project believe in adhering to the principles of nature. &nbsp;
                        <span className="bold">Nature creates and gives abundantly.</span> &nbsp; There is a perpetual give and take, an endless dance.
                    </p>
                    <p>
                        Gift-giving has been a cornerstone in cultures across the world. Gift-giving creates strong bonds
                        between community members, and creates a constant flow of reciprocity.
                    </p>
                    <p className="bold">
                        Scales is designed to:
                    </p>
                    <p className="order">
                        1. Share beautiful art with the world  <br/>
                        2. Create abundance for the Community: Through secondary sales of Scales to collectors <br/>
                        3. Create abundance for Collectors : Through The Royalty Club <br/>
                        4. Create abundance for the Scales Team: Through royalties on secondary sales <br/>
                        5. Foster an open attitude of giving and community in Web 3 <br/>
                        6. Support emerging talent in the 1/1 space <br/>
                        7. Foster creativity through open collaboration with other artists
                    </p>
                    <p>
                        Everyone who obtains a Scale becomes part of this experiment.
                    </p>
                    <p>
                        When you obtain a Scale, either by minting it, buying it on secondary marketplaces or receiving it from us, you become a participant.
                    </p>
                    <p className="bold">
                        What will you do with your Scales? 
                    </p>
                    <p>
                        Will you HODL them? <br/>
                        Will you sell them on the secondary market? <br/>
                        Will you collect 4 Scales in a row, and join the Royalty Club? <br/>
                        Will you use the Scale itself to create more art? <br/>
                    </p>
                    <p className="bold">
                        The choice is yours. You decide how you want to play. 
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Vision