import React from "react";
import "./index.css"
import SectionHeader from "../../../components/SectionHeader";

import AboutImage1 from "../../../assets/images/Grid_Paintings/aboutImage1.jpg"
import AboutImage2 from "../../../assets/images/Grid_Paintings/aboutImage2.jpg"

const About = () => {
    return (
        <div className="about" id="about">
            <div className="about-wrapper">
                <SectionHeader
                    section={"ABOUT SCALES"}
                    description={`Scales is Anna's latest collection of oil paintings employing highly sought after abstract techniques - converted into 10,000 completely unique NFTs.`}
                />
                <div className="about-content content">
                    <div className="about-left-content">
                        <p>
                            Beginning life as a physical painting, each piece is 
                            transitioned to the digital by subdividing the 
                            original canvas into individual "Scales".
                        </p>
                        <p className="fw-700">
                            <em>
                                A Scale is an individual artwork, wholly unique, 
                                yet an equal microcosm of a greater work. These 
                                works paradoxically exist as both partial, and full 
                                ownership of a complete artwork.
                            </em>
                        </p>
                        <p>
                            Together, the Scales in a series form a complete 
                            painting, and every Scale can be digitally 
                            displayed at up to 46 in² with perfect clarity. &nbsp;
                            <span>Scales are 4600x4600 px.</span> &nbsp;To view 
                            the entire collection, and plan your strategy, browse the Scales print shop&nbsp;
                            <a href="https://www.scalesfineart.com/collections" target="_blank">here.</a>
                        </p>
                        <p>
                            These paintings, as NFTs, democratize ownership 
                            of Anna's artwork across many collectors and 
                            facilitate collection of adjacent Scales. Collectors
                             may wish to own a single Scale, an entire work 
                             composed of many Scales, or any other 
                             combination for investment and polyptych display.
                        </p>
                        <p>
                            Each Scale is minted only once (1 of 1), making each truly inimitable.
                        </p>
                        <p className="">
                            Any collector of four Scales in a row, from 
                            any painting, becomes part of the Royalty 
                            Club and is granted access to minting rights,
							giveaways, discounts, community events and royalty sharing. 
                        </p>
                        <p className="">
                            JOIN the GAME. Be rewarded.
                        </p>
                    </div>
                    <div className="about-right-content">
                        <img src={AboutImage1} className="image1"/>
                        <div className="about-image-title">“Truth is a Helium Balloon” </div>
                        <img src={AboutImage2} className="image2"/>
                        <div className="about-image-title">“Freud's Shadow According to Jung”</div>
                    </div>
                </div>

                <div className="about-content-narrow content">
                    <div className="about-content-top">
                        <p>
                            Beginning life as a physical painting, each piece is 
                            transitioned to the digital by subdividing the 
                            original canvas into individual "Scales".
                        </p>
                        <p className="fw-700">
                            <em>
                                A Scale is an individual artwork, wholly unique, 
                                yet an equal microcosm of a greater work. These 
                                works paradoxically exist as both partial, and full 
                                ownership of a complete artwork.
                            </em>
                        </p>
                        <p>
                            Together, the Scales in a series form a complete 
                            painting, and every Scale can be digitally 
                            displayed at up to 46 in² with perfect clarity. &nbsp;
                            <span>Scales are 4600x4600 px.</span> &nbsp;To view 
                            the entire collection, and plan your strategy, browse the Scales print shop&nbsp;
                            <a href="https://www.scalesfineart.com/collections" target="_blank">here.</a>
                        </p>
                    </div>
                    <div className="about-content-middle">
                        <div className="about-content-middle-wrap">
                            <img src={AboutImage1} className="image1"/>
                            <div className="about-image-title">“Truth is a Helium Balloon” </div>
                            <img src={AboutImage2} className="image2"/>
                            <div className="about-image-title">“Freud’s Shadow According to Jung”</div>
                        </div>
                    </div>
                    <div className="about-content-bottom">
                        <p>
                            These paintings, as NFTs, democratize ownership 
                            of Anna's artwork across many collectors and 
                            facilitate collection of adjacent Scales. Collectors
                             may wish to own a single Scale, an entire work 
                             composed of many Scales, or any other 
                             combination for investment and polyptych display.
                        </p>
                        <p>
                            Each Scale is minted only once (1 of 1), making each truly inimitable.
                        </p>
                        <p className="">
                            Any collector of four Scales in a row, from 
                            any painting, becomes part of the Royalty 
                            Club and is granted access to minting rights,
							giveaways, discounts, community events and royalty sharing. 
                        </p>
                        <p className="">
                            JOIN the GAME. Be rewarded.
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default About

