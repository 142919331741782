import React from "react";
import "./index.css";
import SectionHeader from "../../../components/SectionHeader";

const Roadmap = () => {
    return (
        <div className="roadmap" id="roadmap">
            <SectionHeader
                section={`Roadmap`}
                description={`This is the road we are taking`}
            />
            <div className="roadmap-content content">
                <p>
                    Scales is a project with no VC money, no billionaire-backed platform, no community managers 
                    orchestrating hype, and no large social following to draw from. We are a grassroots movement 
                    starting from the ground up, and are relying on the support and engagement from the community 
                    to create growth organically.
                </p>
                <p>
                    Beginning in 2022 and spanning the course of multiple years, Anna will be creating over 
					400 original oil paintings, a process which she is documenting <a href="https://www.x.com/itsannajudd" target="_blank">@ScalesNFT </a>
					on Twitter and in her Substack newsletter, <a href="https://annajudd.substack.com/" target="_blank">HERE</a>.
                </p>
                <p>
                    Our goal is to release 500-1000 scales in multiple drops until 10,000 have been created.
                </p>
                <p>
                    As Anna's art evolves, we anticipate that this project will also evolve in wonderful and surprising 
                    ways. The scope of how we will to give back to the community will depend on this evolution.
                </p>
                <p>
                    We're excited at the prospect of being able to create & give back even more. 
                </p>
                <p>
                    This is our roadmap, although we may find (and already have found) it necessary to go off the beaten path.
                </p>
            </div>
        </div>
    )
}

export default Roadmap