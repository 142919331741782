import React from "react";
import "./RoyaltyClubContent.css"
import RoyaltyClubImg1 from "../../../assets/images/Royalty_Club/RoyaltyClubImg.png"
import RoyaltyClubImg2 from "../../../assets/images/Royalty_Club/RoyaltyClubImg2.png"

const RoyaltyClubContent = () => {
    return (
        <div className="royalty-club-content content">
            <div className="royalty-club-content-text">
                <span>To play, you will try to collect four scales in a row</span> - across, or down in 
                any painting. There are over 500 complete paintings in the scales 
                collection, and 2,500 opportunities to get a seat in the <span>Royalty Club.</span>
            </div>
            <div>
                <img src={RoyaltyClubImg1} className="RoyaltyClubImg1"/>
                <img src={RoyaltyClubImg2} className="RoyaltyClubImg2"/>
            </div>
        </div>
    )
}

export default RoyaltyClubContent