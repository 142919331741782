import { ethers } from 'ethers';
import { NFTContractAddress } from "../constants/config";




const getContract =async () => {
  if(window.ethereum){
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contractABI = require("../constants/NFTContract.json");
    const contract = new ethers.Contract(NFTContractAddress, contractABI, signer);
    return contract
  }    
}

export default getContract