import React from "react";
import TermHeader from "./TermHeader";
import TermsContent from "./TermsContent";

const Terms = () => {
    return (
        <div className="terms">
            <TermHeader/>
            <TermsContent/>
        </div>
    )
}

export default Terms;