import React from "react";
import "./index.css";
import SectionHeader from "../../../components/SectionHeader";
import DiscordIcon from "../../../assets/icons/discord.svg";
import TwitterIcon from "../../../assets/icons/twitter.svg";
// import 

const Contact = () => {
    return (
        <div className="contact" id="contact">
            <SectionHeader
                section = {`CONTACT`}
                description = {`You can get with us, or you can get with that`}
            />
            <div className="contact-content content">
                <a className="mr-30" href="https://discord.gg/z48EywDkuD" target="_blank">
                    <img src={DiscordIcon} className="contact-icon"/>
                </a>
                <a href="https://twitter.com/scalesnft" target="_blank">
                    <img src={TwitterIcon} className="contact-icon"/>
                </a>            
            </div>
        </div>
    )
}

export default Contact