import React from "react";

// import "./index.css"

const Header = () => {
    return (
        <div className="terms-header header content">
            TERMS
        </div>
    )
}

export default Header