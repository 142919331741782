import React, {useState} from "react";
import "./index.css";
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
const CollapsibleComponent = (props) => {

    const [extended, setExtended] = useState(false);
    const contents = props.contents;
    const link = props.link;
    const collapsibleComponentHandler = () => {
        setExtended(!extended);
    }

    const setContentAsLink = (contents, link) => {
        var beforeLink = "";
        var afterLink = "";
        var components;     
        const temp = contents.map((content) => {
            if(content.split(link).length === 2) {
                beforeLink = content.split(link)[0];
                afterLink = content.split(link)[1];
                components =<p className="collapsible-content">{beforeLink}<a href={link} target="_blank">{link}</a>{afterLink}</p>
                return components
            } 
            else{
                components = <p className="collapsible-content">{content}</p>
                return components
            }
            
        });
        return temp;
    }

    return (
        <div className={extended === true ? "collapsibleComponent extended" : "collapsibleComponent collapsed"}>
            <div className="collapsible-header-wrapper">
                <div className="collapsible-header" onClick={collapsibleComponentHandler}>
                    <p>
                        {props.header}
                    </p>
                    {!extended ? 
                        <AddIcon/>
                        :
                        <RemoveIcon/>
                    }
                </div>
            </div>
            <Collapse in={extended}>
                {setContentAsLink(contents, link)}
            </Collapse>
            
        </div>
    )
}

export default CollapsibleComponent