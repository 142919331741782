import getContract from "./getNFTContract";

export const mint = async () => {
    const contract = await getContract();
    try {
        await contract.mint();
        return "Congratulations, your mint was successful!";
    } catch (error) {
        console.log(error);
        console.log(error.message);
        console.log(error.message.error);

        return error.data.message;
    }
}

export const mintBatch = async (to, count) => {
    const contract = await getContract();
    try {
        await contract.mintBatch(to, count);
        return "Congratulations, your mint was successful!";
    } catch ( error) {
        return error.data.message;
    }
}

export const getTotalMintedAmount = async () => {
    // return 100;
    const contract = await getContract();
    var amount;
    try {
        amount = await contract.getTotalMintedAmount();
        return amount;
    } catch (error) {
        console.log(error);
        return "can't get total minted amount"
    }    
}

export const getNewAddedAmount = async () => {
    const contract = await getContract();
    var newAddedAmount;
    try {
        newAddedAmount = await contract.getNewAddedAmount();
        return newAddedAmount;
    } catch (error) {
        console.log(error);
    }
}

export const getTotalDroppedAmount = async () => {
    // return 100;
    const contract = await getContract();
    var amount;
    try {
        amount = await contract.getTotalDroppedAmount();
        return amount;
    } catch (error) {
        console.log(error);
        return "can't get total dropped amount"
    }    
}

export const getBalance = async (address) => {
    const contract = await getContract();
    var balance;
    try {
        balance = await contract.balanceOf(address);
        return balance.toNumber();
    } catch (error) {
        console.log(error);
    }
}

export const tokenOfOwnerByIndex = async (ownerAddress, tokenIndex) => {
    const contract = await getContract();
    let tokenId;
    try {
        tokenId = await contract.tokenOfOwnerByIndex(ownerAddress, tokenIndex);
        return tokenId.toNumber()
    } catch (error) {
        console.log(error);
    }
}

export const tokenURI = async (tokenId) => {
    const contract = await getContract();
    let tokenURI;
    try {
        tokenURI = await contract.tokenURI(tokenId);
        return tokenURI;
    } catch (error) {
        console.log(error)
    }
}