import React from "react";
import Button from '@mui/material/Button';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import "./index.css";

const ScrollToTopButton = () => {

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div>
            <Button className="ScrollToTopButton" variant="outlined" onClick={goToTop}>
                <ArrowUpwardIcon/>            
            </Button>
        </div>
        
    )
}

export default ScrollToTopButton