import React from "react";
import HeroSection from "./HeroSection"
import About from "./About";
import Gallery from "./Gallery";
import RoyaltyClub from "./RoyaltyClub";
import Vision from "./Vision";
import Team from "./Team";
import Roadmap from "./Roadmap";
import FAQ from "./FAQ";
import Contact from "./Contact";

const Minting = (props) => {

    return (
        <div>
            <HeroSection
                onClickMintButton={props.onClickMintButton}
                onClickMintbatchButton={props.onClickMintbatchButton}
                walletAddress={props.walletAddress}
            />
            <About/>
            <Gallery/>
            <RoyaltyClub/>
            <Vision/>
            <Team/>
            <Roadmap/>
            <FAQ/>
            <Contact/>
        </div>

    )
}

export default Minting