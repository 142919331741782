import React from "react";
import RoyaltyClubContent from "./RoyaltyClubContent";
import RoyaltyClubFAQ from "./RoyaltyClubFAQ";
import SectionHeader from "../../../components/SectionHeader";
const RoyaltyClub = () => {
    return (
        <div className="royalty-club" id="royalty_club">
            <SectionHeader
                section={"Royalty Club"}
                description={`Scales is a game of collecting. Like Monopoly, combined with tic-tac-toe.`}
            />
            <RoyaltyClubContent/>
            <RoyaltyClubFAQ/>
        </div>
    )
}

export default RoyaltyClub