import React from "react";
import Button from '@mui/material/Button';
import "./index.css";
import WalletConnectButton from "../../components/WalletConnectButton";

const Navbar = (props) => {

  const pages = ['About', 'Royalty Club', 'Vision', 'Team', 'Roadmap', 'FAQ', 'Contact'];
  const pageId = ['about', 'royalty_club', 'vision', 'team', 'roadmap', 'faq', 'contact']
  
    return (
        <div className="navbar" id="navbar">
            <a className="name-logo" href="#hero">
                SCALES by ANNA JUDD
            </a>
            
            <div className="d-flex align-item-center">
              <div className="nav-menu">
                  {pages.map((page, index) => (
                    <Button
                      key={pageId[index]}
                      className="color-black navbar-item"
                      href={'/#'+pageId[index]}
                      style={{textTransform: 'capitalize'}}
                    >
                      {page}
                    </Button>
                  ))}
                  <Button className="color-black navbar-item" href="/portal" style={{textTransform: 'capitalize'}}>
                    Claim royalties
                  </Button>
                </div>
                
                <WalletConnectButton
                  onClickConnectWallet={props.onClickConnectWallet}
                  onClickDisconnectWallet={props.onClickDisconnectWallet}
                  walletAddress={props.walletAddress}
                />
            </div>
            
        </div>
    )
}

export default Navbar
