import React from "react";
import Button from "@mui/material/Button";
import "./index.css"
import saveAs from "file-saver"
import { Typography } from "@mui/material";

const NFTCard = (props) => {
    
    const DownloadImageHandler = () => {
        let filename = props.name + " " + props.position;
        saveAs(props.image, filename)
    }
    return (
        <div className="nft-card">
            <div className="nft-image-wrap">
                <img src={props.image} className="nft-image" onClick={props.ImgClickHandler} />
                <div className="nft-card-overlay">
                    Click Image to zoom
                </div>
            </div>
            <div>
                <p><span className="bold">Painting: </span>{props.name}</p>
                <p><span className="bold">Scales: </span>{props.position}</p>
            </div>
            <Button variant="contained"  className="nft-download-button" onClick={DownloadImageHandler}  download="image.jpg">
                Download
            </Button>

        </div>
    )
}

export default NFTCard