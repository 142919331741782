import getContract from "./getRoyaltyPoolContract";

export const getShareValue = async () => {
    // return 100;
    const contract = await getContract();
    console.log(contract)
    var amount;
    try {
        amount = await contract.getShareValue();
        return amount;
    } catch (error) {
        console.log(error);
        return "can't get shareValue"
    }
}

export const getNFTSetsAmounts = async (holder) => {
    // return 100;
    const contract = await getContract();
    var amount;
    try {
        amount = await contract.getNFTSetsAmounts(holder);
        return amount;
    } catch (error) {
        console.log(error);
        return "can't get NFTSetsAmount"
    }
}

export const getRewardAmount = async (holder) => {
    // return 100;
    const contract = await getContract();
    var amount;
    try {
        amount = await contract.getRewardAmount(holder);
        return amount;
    } catch (error) {
        console.log(error);
        return "can't get RewardAmount"
    }    
}

export const withdrawRoyaltyReward = async () => {
    const contract = await getContract();
    let result;
    try {
        result = await contract.withdrawRewards();
        return "success"
    } catch (error) {
        console.log(error);
        return "false";
    }
}