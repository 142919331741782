import React, { useEffect, useState } from "react";
import Minting from "../pages/Minting";
import Portal from "../pages/Portal";
import Terms from "../pages/Terms";
import Navbar from "./Navbar";
import Footer from "./Footer";
import CollapsibleMenu from "../components/CollapsibleMenu";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Display from "../pages/Display";

import "../assets/css/main.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import { connectWallet, getCurrentWalletConnected } from "../utils/walletInteraction";
import { mint, mintBatch } from "../utils/NFTContractFunctions";

const Layout = (props) => {

    const [walletStatus, setWalletStatus] = useState('')
    const [walletAddress, setWalletAddress] = useState('')

    useEffect(() => { 
        const init =async() => {
            const { address, status } =  await getCurrentWalletConnected()
            setWalletAddress(address)
            setWalletStatus(status)
        }
        init()
        
    }, []);

    const notify = () => toast.info(walletStatus, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    useEffect(() => {
        if (walletStatus) {
            notify();
            setWalletStatus(null);
        }  
    }, [walletStatus])  

    const onClickConnectWallet = async () => {
        const walletResponse = await connectWallet()
        setWalletStatus(walletResponse.status);
        setWalletAddress(walletResponse.address);
    }
    
    const onClickDisconnectWallet = async () => {
        setWalletAddress('')
        setWalletStatus('Disconnected from the site.')       
    }

    const onClickMintButton = async () => {
        if(walletAddress) {
            setWalletStatus("Minting is waiting for you to confirm");
            var status;
            status = await mint();
            setWalletStatus(status);
        }
        else (
            setWalletStatus("You should connect wallet before minting")
        )        
    }

    const onClickMintbatchButton = async () => {
        if(walletAddress) {
            setWalletStatus("Minting is waiting for you to confirm");
            var status;
            status = await mintBatch(walletAddress, 2);
            setWalletStatus(status);
        }
        else (
            setWalletStatus("You should connect wallet before minting")
        )        
    }

    return (        
        <div className="Layout">
            <Navbar
                onClickConnectWallet={onClickConnectWallet}
                onClickDisconnectWallet={onClickDisconnectWallet}
                walletAddress={walletAddress}
            />
            <CollapsibleMenu/>
            <div className="body">
                {
                    props.bodyName === "minting" && 
                    <Minting
                        onClickMintButton={onClickMintButton}
                        onClickMintbatchButton={onClickMintbatchButton}
                        walletAddress={walletAddress}
                    />
                }
                {
                    props.bodyName === "terms" && <Terms/>
                }
                {
                    props.bodyName === "portal" && <Portal
                        walletAddress={walletAddress}
                    />
                }
                {
                    props.bodyName === "display" && <Display
                        walletAddress={walletAddress}
                    />
                }
            </div>
            <Footer/>
            <ScrollToTopButton/>            
            <ToastContainer />
        </div>
    )
}

export default Layout