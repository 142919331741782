import React, { useState } from "react";
import Button from "@mui/material/Button";
import "./index.css";

const WalletConnectButton = (props) => {
    
    const connectButton = () => {
        return (
            <Button variant="contained"  className="connect-button" onClick={props.onClickConnectWallet}>
                Connect Wallet
            </Button> 
        )
    }

    const showWalletAddress = () => {
        return (
            <Button variant="contained"  className="connect-button" onClick={props.onClickDisconnectWallet}>
                {props.walletAddress.substring(0, 8)}...{props.walletAddress.substring(36, 42)}
            </Button> 
        )
    }

    return (
        <div>
            {!props.walletAddress ? connectButton() : showWalletAddress()}
        </div>
         
    )
}

export default WalletConnectButton