import React from "react";
import linkedinImg from "../../assets/icons/linkedin.svg";
import twitterImg from "../../assets/icons/twitter.svg";
import instagramImg from "../../assets/icons/instagram.svg";
import "./index.css";

const PersonalCard = (props) => {

    const linkedin = () => {
        return (
            <a href={props.linkedin}>
                <img src={linkedinImg} className="social-media-icon"/>
            </a>
        )
    };

    const twitter = () => {
        return (
            <a href={props.twitter}>
                <img src={twitterImg}  className="social-media-icon"/>
            </a>
        )
    };

    const instagram = () => {
        return (
            <a href={props.instagram} >
                <img src={instagramImg} className="social-media-icon"/>
            </a>
        )
    };

    return (
        <div className="personal-card">
            <div className="personal-photo-wrap">
                <img src={props.photo} className="personal-photo"/>
            </div>
            <div className="personal-info">
                <div className="personal-info-name">
                    {props.name}
                </div>
                <div className="personal-info-role">
                    {props.role}
                </div>
                <div className="social-media">
                    {props.linkedin ?  linkedin() : null}
                    {props.twitter ? twitter() : null}
                    {props.instagram ? instagram() : null}
                </div>
            </div>
        </div>
    )
}

export default PersonalCard