import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import CollapsibleList from "../../../components/CollapsibleList";

const FAQ = () => {
    const items = [
        {
            header: `How can I join the Scales Community?`,
            contents : [
                `Scales will be conducting drops of 500-1000 NFTs until the collection reaches 10,000 Scales. As of March, 2024 6,508 Scales have been minted in TEN highly 
				anticipated drops. New drop dates are publicly announced as the artwork is completed. Join the Discord and follow us on Twitter here`, 
				` https://twitter.com/scalesnft and be the first to get future drop dates!`
            ],
            link: ""
        },
        {
            header: `I'm new to NFTs. How can I get a couple Scales?`,
            contents : [
                `1) Download metamask wallet: https://metamask.io/download/`,
                `2) Put some ETH in your metamask wallet for gas.`,
                `3) Apply for the allowlist in our discord or via Twitter DMs.`,
                `4) Mint 2 Scales for free on this website.`,
                `5) If we're out of free Scales here, visit secondary NFT marketplaces to collect and trade.`
            ],
            link: "https://metamask.io/download/"
        },
        {
            header: `When can I mint one?`,
            contents: [
                `If you are on our allowlist, you will be able to mint in our next drop. Join our Discord and follow us on 
				Twitter @ https://twitter.com/scalesnft and be the first to get drop dates and apply to mint Scales for free.`
            ],
            link: "https://twitter.com/scalesnft"
        },
        {
            header: `How many Scales are available?`,
            contents: [
                `A new drop of Scales will be scheduled for release until 10,000 are minted. That's it, forever.`
            ],
            link: ""
        },
        {
            header: `What Blockchain are you using?`,
            contents: [
                `Ethereum.`
            ],
            link: ""
        },
        {
            header: `Which wallets can I use to mint a Scale?`,
            contents: [
                `You can use Metamask.`
            ],
            link: ""
        },
        {
            header: `Will there be a presale?`,
            contents: [
                `No. The Scales project is a free mint project. If you've missed your chance to mint a free Scale, use NFT marketplaces to purchase from owners on the secondary market.`
            ],
            link: ""
        },
        {
            header: `Is there an allowlist to mint?`,
            contents: [
                `Yep. To mint a free Scale, you'll need to be allowlisted. We are aiming for the widest distribution of Scales possible. Everyone will have the same opportunity to mint two scales for free and purchase Scales on the secondary market.`
            ],
            link: ""
        },
        {
            header: `Where can I view my Scales?`,
            contents: [
                `Once you mint your Scales, you will be able to view them on all marketplaces hosting the Scales collection. You may also download your Scales in HD (4600x4600px) from our display page by clicking https://www.scalesnft.com/display.`
            ],
            link: "https://www.scalesnft.com/display"
        },
        {
            header: `What are your secondary Royalties?`,
            contents: [
                `Secondary royalties of 1-15% are optional on most NFT marketplaces. When we do receive royalties, half go directly to Royalty Club members. (Collectors who hold 4 adjacent scales in any painting.)`
            ],
            link: ""
        },
        {
            header: `What is the Royalty Club?`,
            contents: [
                `See the above section titled, Royalty Club.`
            ],
            link: ""
        },
        {
            header: `Will the scales I mint be in a row?`,
            contents: [
                `Scales are minted randomly. When you mint two Scales, the chances that you will receive two Scales in a row is not likely, but also not impossible. In order to collect more and join the Royalty Club, you will have to purchase these scales from other owners on secondary marketplaces.`
            ],
            link: ""
        },
        {
            header: `How are you supporting the artistic community?`,
            contents: [
                `We have gifted over 500 Scales to established visual artists as well as emerging talent in the space. Distribution of Scales between both creators and collectors promotes a vibrant secondary marketplace.`
            ],
            link: ""
        },
        {
            header: `How can I communicate with owners of the Scales I am trying to collect?`,
            contents: [
                `Join our Discord to chat with other Scales collectors in the “Collectors Corner” channel and hunt down owners on Twitter using #scalesmint.`
            ],
            link: ""
        },
        {
            header: `Am I allowed to use this scale to create new art?`,
            contents: [
                `Artists are encouraged to use Scales that they own to make collaborative works. Outside of creating art for web3, we respectfully ask that you not use or sell the Scales for commercial purposes. `,
                `We ask that you always give credit to this project in any collaboration, and how/if you decide to reciprocate any earnings from your sales of these NFTs is up to you.`
            ],
            link: ""
        },
        {
            header: `How do I get free Scales?`,
            contents: [
                `Everyone who wants a free Scale can follow @scalesnft and @itsannajudd on Twitter, DM and engage with opportunities to join the allowlist. Our focus is on supporting those who make positive contributions to web3, either through their creative work or their generosity in helping us spread the word.`
            ],
            link: ""
        },
        {
            header: `Where can I see all the paintings and plan my game?`,
            contents: [
                `Visit our incredible Scales print shop at https://scalesfineart.com to see all of the paintings and individual Scales. This is the main hub to view the art and plan your collection game.`
            ],
            link: "https://scalesfineart.com"
        },
        {
            header: `Are you doing giveaways?`,
            contents: [
                `Yes! In accordance with the laws of nature, we are gifting all 10,000 scales to the community.`
            ],
            link: ""
        },
        {
            header: `How can I help the community grow?`,
            contents: [
                `NFT projects thrive off a healthy community. You can help grow the community by engaging with the team and each other on our Discord and having proactive and supportive conversation. Invite your friends, get people excited about the Royalty Club and minting free Scales!`
            ],
            link: ""
        },
        {
            header: `How long have you been working on the project?`,
            contents: [
                `Anna has spent the better part of 20 years as a visual artist and began creating work for the Scales collection in early 2021.`
            ],
            link: ""
        },
        {
            header: `Do you plan on releasing other NFT projects in the future?`,
            contents: [
                `Scales is a one of a kind collection and will only have 10,000 pieces minted. When the Scales collection finishes minting, the sky's the limit. Join our Discord and let us know what you'd like to see in the future.`
            ],
            link: ""
        },
        {
            header: `Who else is on the team?`,
            contents: [
                `For now, the core team consists of 11 members from across the globe. We're always looking for more incredible talent to add to our team.`
            ],
            link: ""
        },
        {
            header: `Anything else?`,
            contents: [
                `We will be exploring the possibility of a community pool to finance projects both within our community and elsewhere. We are also very excited about possibilities around virtual galleries, expansion into metaverse and exclusive auctions.`
            ],
            link: ""
        },
    ]
    return (
        <div className="faq" id="faq">
            <SectionHeader
                section={`FAQ`}
                description={`Got Questions? We've got Answers`}
            />
            <div className="content">
                <CollapsibleList
                    items={items}
                />
            </div>
        </div>
    )
}

export default FAQ