import React from "react";
import PortalHeader from "./PortalHeader";
import PortalContent from "./PortalContent";

const Portal = (props) => {
    return (
        <div className="portal">
            <PortalHeader/>
            <PortalContent
                walletAddress={props.walletAddress}
            />
        </div>
        
    )
}

export default Portal