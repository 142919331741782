import React from "react";
import CollapsibleComponent from "./CollapsibleComponent";

const CollapsibleList = (props) => {
    const items = props.items;
    return (
        <div>
            {items.map((item) =>
                <CollapsibleComponent
                    header={item.header}
                    contents={item.contents}
                    link={item.link}
                />            
            )}
        </div>
        
    )
}

export default CollapsibleList