import {React, useState, useEffect} from "react";
import NFTCard from "../../../components/NFTCard";
import "./index.css"
import { tokenOfOwnerByIndex, tokenURI } from "../../../utils/NFTContractFunctions";
import axios from "axios"
import ZoomNFT from "../ZoomNFT";
import { ImageBaseURI_Metadata, ImageBaseURI_Display } from "../../../constants/config";
const DisplayNFT = (props) => {
    let tokenIds = [];
    let tokenURIs = [];
    let temp_nftImages = [];
    let temp_nftNames = [];
    let temp_nftPositions = [];
    const [nftImages, setNftImages] = useState([]);
    const [nftNames, setNftNames] = useState([]);
    const [nftPositions, setNftPositions] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(0);
    console.log("display page==========");

    const [zoomStatus, setZoomStatus] = useState(false);
    const [zoomNFTImageURL, setZoomNFTImageURL] = useState("");
    useEffect(() => {
        console.log("display page");
        const getNFTInfo = async () => {
            for(let tokenIndex = 0; tokenIndex < props.NFTAmount; tokenIndex++) {
                tokenIds[tokenIndex] = await tokenOfOwnerByIndex(props.walletAddress, tokenIndex);
                tokenURIs[tokenIndex] = await tokenURI(tokenIds[tokenIndex]);
                await axios.get(tokenURIs[tokenIndex])
                    .then((response) => {
                        let temp =  response.data.image;
                        console.log("temp", temp);
                        temp_nftImages[tokenIndex] = ImageBaseURI_Display + temp.split(ImageBaseURI_Metadata)[1];
                        console.log("updated", temp_nftImages[tokenIndex])
                        let name = response.data.name;
                        temp_nftNames[tokenIndex] = name.slice(0, -3);
                        temp_nftPositions[tokenIndex] = name.slice(-2)
                        // setLoadingStatus(1);
                    })
            }
            setNftNames(temp_nftNames);
            setNftImages(temp_nftImages);
            setNftPositions(temp_nftPositions);
        }
        // setLoadingStatus(0);
        getNFTInfo()

    }, [])

    // useEffect(() => {
    //     console.log("status", loadingStatus);
    // },[loadingStatus])
    useEffect(()=> {
        console.log("nft names: ", nftNames)
    },[nftNames])

    const ImgClickHandler = (NFTImageURL) => {
        setZoomStatus(true)
        setZoomNFTImageURL(NFTImageURL)
    }

    const closeZomeNFT = () => {
        setZoomStatus(false)
    }
    return (
        <div>
            {zoomStatus ?
            <ZoomNFT closeZomeNFT={closeZomeNFT} zoomNFTImageURL={zoomNFTImageURL}/> :
            <div className="display-nft content">
                {nftNames.map((nftName, index) =>(
                    <div className="nft">
                        <NFTCard
                            image={nftImages[index]}
                            name={nftNames[index]}
                            position={nftPositions[index]}
                            ImgClickHandler={() => ImgClickHandler(nftImages[index])}
                        />
                    </div>          
                )
                )}                    
            </div>
            }
        </div>
    )
}

export default DisplayNFT