import React from "react";
import "./index.css";

import SectionHeader from "../../../components/SectionHeader";
import PersonalCard from "../../../components/PersonalCard";

import AnnaPhoto from "../../../assets/images/Team_Avatar_Photos/Anna.jpg";
import MattPhoto from "../../../assets/images/Team_Avatar_Photos/Matt.jpg";
import HeChangPhoto from "../../../assets/images/Team_Avatar_Photos/HeChang.jpg";
import CoraPhoto from "../../../assets/images/Team_Avatar_Photos/Cora.jpg";
import RyanPhoto from "../../../assets/images/Team_Avatar_Photos/Ryan.jpg";
import ChristinePhoto from "../../../assets/images/Team_Avatar_Photos/Christine.jpg";
import MarissaPhoto from "../../../assets/images/Team_Avatar_Photos/Marissa.jpg";
import BarbiePhoto from "../../../assets/images/Team_Avatar_Photos/Barbie.jpg";
import DainPhoto from "../../../assets/images/Team_Avatar_Photos/Dain.jpg";
import KassiePhoto from "../../../assets/images/Team_Avatar_Photos/Kassie.jpg";
import TimPhoto from "../../../assets/images/Team_Avatar_Photos/Tim.jpg";

const Team = () => {
    return (
        <div className="team" id="team">
            <SectionHeader
                section={`Team`}
                description={`Our team is so fine`}
            />
            <div className="team-content content">
                    <div className="flex-container" >
                        <div className="team-member">
                            <PersonalCard 
                                name={"Anna Judd"}
                                role={"Artist/Founder"}
                                photo={AnnaPhoto}
                                linkedin={""}
                                twitter={"https://www.twitter.com/itsannajudd"}
                                instagram={"https://www.instagram.com/womanologist"}
                            />
                        </div>
                        <div className="team-member">
                            <PersonalCard
                                name={"Matthew Miller"}
                                role={"Director/Founder"}
                                photo={MattPhoto}
                                linkedin={"https://www.linkedin.com/in/liion/"}
                                twitter={""}
                                instagram={""}
                            />
                        </div>
                        <div className="team-member">
                            <PersonalCard
                                name={"HeChang Lee"}
                                role={"Blockchain Lead Dev"}
                                photo={HeChangPhoto}
                                linkedin={"https://www.linkedin.com/in/he-chang-l-a729b0236/"}
                                twitter={""}
                                instagram={""}
                            />
                        </div>
                        <div className="team-member">
                            <PersonalCard
                                name={"Cora Taylor"}
                                role={"Digital Alchemist"}
                                photo={CoraPhoto}
                                linkedin={""}
                                twitter={"https://twitter.com/CoraCalligraphy"}
                                instagram={""}
                            />
                        </div>
                        <div className="team-member">
                            <PersonalCard
                                name={"Ryan Garza"}
                                role={"Digital Alchemist"}
                                photo={RyanPhoto}
                                linkedin={""}
                                twitter={""}
                                instagram={"#"}
                            />
                        </div>
                        <div className="team-member">
                            <PersonalCard
                                name={"Christine Miller"}
                                role={"Digital Alchemist"}
                                photo={ChristinePhoto}
                                linkedin={"#"}
                                twitter={""}
                                instagram={""}
                            />
                        </div>
                        <div className="team-member">
                            <PersonalCard
                                name={"Marissa Wright"}
                                role={"Digital Alchemist"}
                                photo={MarissaPhoto}
                                linkedin={""}
                                twitter={"https://twitter.com/babyvoorheesart"}
                                instagram={"https://www.instagram.com/baby_voorhees"}
                            />
                        </div>
                        <div className="team-member">
                            <PersonalCard
                                name={"Barbie Godoy"}
                                role={"Digital Alchemist"}
                                photo={BarbiePhoto}
                                linkedin={""}
                                twitter={""}
                                instagram={"https://www.instagram.com/barbie.godoy"}
                            />
                        </div>
                        <div className="team-member">
                            <PersonalCard
                                name={"Dain Fowler"}
                                role={"Digital Alchemist"}
                                photo={DainPhoto}
                                linkedin={"#"}
                                twitter={"#"}
                                instagram={""}
                            />
                        </div>
                        <div className="team-member">
                            <PersonalCard
                                name={"Kassie Malcor"}
                                role={"Digital Alchemist"}
                                photo={KassiePhoto}
                                linkedin={"#"}
                                twitter={""}
                                instagram={""}
                            />
                        </div>
                        <div className="team-member">
                            <PersonalCard
                                name={"Tim McCall"}
                                role={"Digital Alchemist"}
                                photo={TimPhoto}
                                linkedin={""}
                                twitter={"https://twitter.com/t1mcca11"}
                                instagram={""}
                            />
                        </div>
                    </div>
                
            </div>
            
        </div>
    )
}

export default Team