import {React, useEffect, useState} from "react";
import Status from "../../../components/Status/Status";
import Button from "@mui/material/Button";
import { ethers } from 'ethers'
import "./index.css";
import { getShareValue, getNFTSetsAmounts, getRewardAmount, withdrawRoyaltyReward } from "../../../utils/RoyaltyPoolContractFunctions";

const PortalContent = (props) => {

    const [share, setShare] = useState(0);
    const [reward, setReward] = useState(0);
    const [nftSets, setNFTSets] = useState(0);
    const holder = props.walletAddress;

    useEffect(() => { 
        const getAmounts = async () => {
            // var _shareAmount  = 0;
            var _shareAmount = await getShareValue();
            // _shareAmount = _shareAmount.toNumber();
            console.log("share amount : ", _shareAmount);
            var _NftSetsAmount = await getNFTSetsAmounts(holder);
            console.log("NFT set amount : ", _NftSetsAmount);
            var _rewardAmount = await getRewardAmount(holder);
            console.log("reward amount :", _rewardAmount);     
            // setShare(_shareAmount.toNumber());
            setShare(ethers.utils.formatUnits(_shareAmount));
            setNFTSets(_NftSetsAmount.toNumber());
            setReward(ethers.utils.formatUnits(_rewardAmount));
        }
        getAmounts();
    }, [holder]);

    const takeReward = async () => {
        var result;
        result = await withdrawRoyaltyReward();
        console.log("withdraw result", result);
    }




    return (
        <div className="portal-content content">
            <div className="">
                <p className="fw-700">
                    If you own four Scales in a row (across or down), you are a member of the Royalty Club.
                </p>
                <p>
                    Whenever royalty accumulation reaches levels such that the gas to claim is less than the reward/set, a snapshot of the blockchain is taken. <br/><br/>
                    For each set of four Scales you are hodling at this time, you can claim one royalty share from the pool.
                </p>
                <p>
                    To withdraw your Royalty share(s), simply connect your wallet to the site and use the button below.
                </p>
            </div>
            <div className="portal-info">
                <Status
                    header="Share value"
                    content={share}
                />
                <Status
                    header="Sets you hold"
                    content={nftSets}
                />
                <Status
                    header="Your total royalties"
                    content={reward}
                />
            </div>
            
            {
                reward ? (
                    <Button
                        variant="contained"  
                        className="withdraw-button" 
                        // style={{textTransform: 'capitalize'}}
                        onClick={takeReward}
                    >
                        Withdraw Royalties
                    </Button>
                ) : (
                    <Button
                        variant="contained"  
                        className="withdraw-button" 
                        // style={{textTransform: 'capitalize'}}
                        // onClick={}
                        disabled
                    >
                        Withdraw Royalties
                    </Button>
                )
            }
            <div className="portal-content-notice">
                <p className="">
                    <span>NOTICE:</span> <br/>
                    YOU MUST CLAIM/WITHDRAW YOUR ROYALTY SHARE(S) BEFORE THE NEXT SNAPSHOT.
                </p>
                <p className="">
                    UNCLAIMED SHARES ARE ROLLED OVER INTO THE NEXT POOL AND DIVIDED AMONG ALL CLUB MEMBERS.
                </p>
            </div>
            
        </div>
    )
}

export default PortalContent