import React from "react";
// import "./index.css";

const Header = () => {
    return (
        <div className="portal-header header content">
            ROYALTY CLUB PORTAL
        </div>
    )
}

export default Header