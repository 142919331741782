// import logo from './logo.svg';
import React, { useState } from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./layout";
import './App.css';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout bodyName="minting" />}/>
        <Route path="/terms" element={<Layout bodyName="terms" />}/>
        <Route path="/portal" element={<Layout bodyName="portal" />}/>
        <Route path="/display" element={<Layout bodyName="display" />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
