import React from "react";
import "./index.css"
const NoNFT = () => {
    return (
        <div className="nonft">
            <p className="bold">Uh-oh</p>
            <p> You have no Scales in your wallet. :(</p>
            <p>Don't worry, you can mint 2 scales for free. <br/> To get whitelisted, follow and DM us @ScalesNFT on Twitter</p>
        </div>
    )
}

export default NoNFT