import React from "react";
import "./index.css"
const NoWallet = () => {
    return (
        <div className="nowallet content">
            <p className="bold">Got Scales?</p>
            <p>Connect your wallet to view and download them</p>
        </div>
    )
}

export default NoWallet