import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
const ZoomNFT = (props) => {

    return (
        <Box
            sx={{
                m:1
            }}
        >
            <IconButton 
                sx={{
                    float:"right"
                }}
            >
                <CloseIcon onClick={props.closeZomeNFT}/>
            </IconButton>
            <img src={props.zoomNFTImageURL} className="nft-image"/>
        </Box>
    )
}

export default ZoomNFT