import React from "react";
import "./index.css"
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import img1 from "../../../assets/images/Scales/Scales1.jpg";
import img2 from "../../../assets/images/Scales/Scales2.jpg";
import img3 from "../../../assets/images/Scales/Scales3.jpg";
import img4 from "../../../assets/images/Scales/Scales4.jpg";
import img5 from "../../../assets/images/Scales/Scales5.jpg";
import img6 from "../../../assets/images/Scales/Scales6.jpg";
import img7 from "../../../assets/images/Scales/Scales7.jpg";
import img8 from "../../../assets/images/Scales/Scales8.jpg";
import img9 from "../../../assets/images/Scales/Scales9.jpg";
import img10 from "../../../assets/images/Scales/Scales10.jpg";
import img11 from "../../../assets/images/Scales/Scales11.jpg";
import img12 from "../../../assets/images/Scales/Scales12.jpg";
const Gallery = () => {
    return (
        <div className="gallery">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img1} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img2} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img3} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img4} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img5} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img6} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img7} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img8} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img9} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img10} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img11} className="image1"/>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={4}>
                        <img src={img12} className="image1"/>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Gallery