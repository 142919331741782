import { React, useEffect, useState } from "react";
import NoNFT from "./NoNFT";
import NoWallet from "./NoWallet";
import DisplayNFT from "./DisplayNFT";
import DisplayHeader from "./DisplayHeader";

import { getBalance } from "../../utils/NFTContractFunctions";
const Display = (props) => {


    const currentWalletAddress = props.walletAddress; 
    const [ NFTAmount, setNFTAmount ] = useState();


    useEffect(() => {
        const getNFTAmount = async() => {
            let amount = await getBalance(currentWalletAddress);
            setNFTAmount(amount);
        }

        getNFTAmount();
    }, [currentWalletAddress])
    
    const HasNFT = (props) => {
        return (
            NFTAmount === 0 ? <NoNFT/> : <DisplayNFT walletAddress={props.walletAddress} NFTAmount={props.NFTAmount}/>
        )
    }

    return (
        <div>
            <DisplayHeader/>
            {
                currentWalletAddress === "" ? <NoWallet/> : <HasNFT walletAddress={props.walletAddress} NFTAmount={NFTAmount}/>
            }
            
        </div>
    )
}

export default Display