import React from "react";
import "./RoyaltyClubFAQ.css"
import CollapsibleList from "../../../components/CollapsibleList";

const RoyaltyClubFAQ = () => {

    const collapsibleListItems = 
            [
                {
                    header : `What do I get for being a Royalty Club Member?`,
                    contents: [
                        `1) Every drop Royalty members are granted an allowlist spot to mint 2 Scales for free!`,
						`2) Exclusive access to 1:1 and edition giveaways from other groundbreaking web3 artists.`,
						`3) A permanent 20% discount at the Scales Fine Art print shop at https://scalesfineart.com`,
						`4) Members divide up a 5% pool of *optional* royalties from secondary sales on the entire collection.`

                    ],
                    link: [
                        {
                            content: "",
                            link:"https://scalesfineart.com"
                        }
                    ]
                },
                {
                    header : `How many seats are in the Club?`,
                    contents: [
                        `In theory, there are 2,500 potential seats in the Royalty Club. In reality, there are far fewer due to wide distribution of Scales ownership.`
                    ],
                    link: [
                        {
                            content: "",
                            link:""
                        }
                    ]
                },
                {
                    header : `What if I collect two or more sets?`,
                    contents: [
                        `Holding a single set of Scales qualifies you for all the benefits of Royalty Club membership.`,
						`Each additional set earns you an additonal share of royalty payouts, any time a deposit is made to the RC pool.`,
                        `Example: You own 3 Sets and so receive 3 portions of the Royalty pool.`
                    ],
                    link: [
                        {
                            content: "",
                            link:""
                        }
                    ]
                },
                {
                    header : `How can I join?`,
                    contents: [
                        `Simply collect and hold four adjacent scales in a row (either across or down) - a "Set". As long as you hold them in the same wallet, you will receive the benefits of being Royal.`
                    ],
                    link: [
                        {
                            content: "",
                            link:""
                        }
                    ]
                },
                {
                    header : `Are there any rules?`,
                    contents: [
                        `Each scale can only be used once to form a Set.`
                    ],
                    link: [
                        {
                            content: "",
                            link:""
                        }
                    ]
                },
                {
                    header : `How often are payouts made?`,
                    contents: [
                        `Sporatically. Since royalties became optional on all marketplaces in 2023, payouts have been itty bitty. Tiny royalties accumulate for many months before distribution.`,
						`Claims are made with holdling wallets and include all royalties accumulated in the pool since the prior payout.`,
                        `This schedule is similar to musical chairs. If you are hodling your Set when when the snapshot is taken you qualify for the entire period of royalty accumlation.`
                    ],
                    link: [
                        {
                            content: "",
                            link:""
                        }
                    ]
                },
                {
                    header : `What about gas fees?`,
                    contents: [
                        `There is a gas fee to claim rewards. Your royalty payout therefore must be greater than the fees to claim it.`
                    ],
                    link: [
                        {
                            content: "",
                            link:""
                        }
                    ]
                },
                {
                    header : `How is the Royalty pool divided?`,
                    contents: [
                        `The 5% Royalty pool is divided evenly by the total number of collected sets, into single shares . One share per Set is claimable by wallets holding them.`
                    ],
                    link: [
                        {
                            content: "",
                            link:""
                        }
                    ]
                },
                {
                    header : `Will you notify me if I'm in the Royalty Club or if I have royalties?`,
                    contents: [
                        `No. If you own four scales in a row, check the royalty portal and Royalty Club channel in our discord every month to check your status and claim any royalties.`
                    ],
                    link: [
                        {
                            content: "",
                            link:""
                        }
                    ]
                },
                {
                    header : `How do I claim my royalty payment?`,
                    contents: [
                        `Easy. Simply go to the royalty portal and click 'withdraw royalties'`
                    ],
                    link: [
                        {
                            content: "",
                            link:""
                        }
                    ]
                },
                {
                    header : `What happens if I don't claim my share of royalties?`,
                    contents: [
                        `You must claim/withdraw your royalty share(s) before the next snapshot is made. Unclaimed royalties are rolled over into the next distribution and divided among all club members.`
                    ],
                    link: [
                        {
                            content: "",
                            link:""
                        }
                    ]
                }
            ];

    return (
        <div className="royalty-club-faq content">
            <CollapsibleList
                items={collapsibleListItems}
            />
        </div>
    )
}

export default RoyaltyClubFAQ