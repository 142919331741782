import React from "react";
import "./index.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { NFTContractAddress } from "../../constants/config";

const Footer = () => {
    const raribleLink = `https://rarible.com/scalesnft/items`
    const LooksRareLink = `https://looksrare.org/collections/${NFTContractAddress}`
    const smartContractAddress = `https://etherscan.io/address/${NFTContractAddress}`
    return (
        <div className="footer content">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item lg={6} md={6} sm={6} xs={12} > 
                        <div className="footer-header">
                            SCALES by ANNA JUDD
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}> 
                        <div className="footer-header">
                            MENU
                        </div>
                        <div className="footer-content">
                            <a href="/#hero">Mint</a>
                            <a href="/#about">About</a>
                            <a href="/#royalty_club">Royalty Club</a>
                            <a href="/#vision">Our Vision</a>
                            <a href="/#team">Team</a>
                            <a href="/#roadmap">Roadmap</a>
                            <a href="/#contact">Contact</a>
                            <a href="/#faq">FAQ</a>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}> 
                        <div className="footer-header">
                            LINKS
                        </div>
                        <div className="footer-content">
                            <a href={smartContractAddress} target="_blank">Smart Contract</a>
                            <a href={raribleLink} target="_blank">Rarible</a>
                            <a href={LooksRareLink} target="_blank">LooksRare</a>
                            <a href="terms">Terms</a>
                            <a href="https://annajudd.com/scalesgallery" target="_blank">Gallery</a>
                            <a href="https://annajudd.com" target="_blank">AnnaJudd.com</a>
                            <a href="https://scalesnft.com/portal">Claim Royalties</a>
                            <a href="https://scalesnft.com/display">View my Scales</a>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <div className="copyright">
                Copyright 2022 - SCALES by ANNA JUDD - All rights reserved
            </div>
        </div>

    )
}

export default Footer

