import React from "react";

const DisplayHeader = () => {
    return (
        <div className="display-header header content">
            MY SCALES
        </div>
    )
}

export default DisplayHeader