import React from "react";
import MainHero from "./MainHero";
import Stats from "./Stats";

const HeroSection = (props) => {

    return (
        <div className="hero" id="hero">
            <MainHero                
                onClickMintButton={props.onClickMintButton}
                onClickMintbatchButton={props.onClickMintbatchButton}
            />
            <Stats
                walletAddress={props.walletAddress}
            />
        </div>
    )
}

export default HeroSection