import React, {useState, useEffect} from "react";
import Status from "../../../../components/Status/Status";
import "./index.css";
import { getTotalMintedAmount, getNewAddedAmount, getTotalDroppedAmount } from "../../../../utils/NFTContractFunctions";

const Stats = (props) => {
    const totalAmount = 10000;
    const [totalMintedAmount, setTotalMintedAmount] = useState("0");
    const [scalesLeftInThisDrop, setScalesLeftInThisDrop] = useState("0");
    const [newAddedAmount, setNewAddedAmount] = useState("0");
    const [scalesLeftOnDrop, setScalesLeftOnDrop] = useState("0");
    

    

    useEffect(() => { 
        const getAmounts = async () => {

            var _totalMintedAmount = 0;
            var _newAddedAmount = 0;
            var _totalDroppedAmount = 0;

            _totalMintedAmount = await getTotalMintedAmount();
            _totalMintedAmount = _totalMintedAmount.toNumber();
            console.log("total minted amount : ", _totalMintedAmount);

            _newAddedAmount = await getNewAddedAmount();
            _newAddedAmount = _newAddedAmount.toNumber();
            console.log("new added amount : ", _newAddedAmount);

            _totalDroppedAmount = await getTotalDroppedAmount();
            _totalDroppedAmount = _totalDroppedAmount.toNumber();
            console.log("total dropped amount : ", _totalDroppedAmount);

            setTotalMintedAmount(_totalMintedAmount);
            setNewAddedAmount(_newAddedAmount);
            setScalesLeftInThisDrop(_totalDroppedAmount - _totalMintedAmount);
            setScalesLeftOnDrop(totalAmount - _totalMintedAmount);


        }
        getAmounts();
    }, [props.walletAddress]);
    
    return (
        <div className="stats">
            <div className="content">
                <div className="stats-header">
                    STATS
                </div>
                    <div className="stats-content">
                        <Status
                            header="Scales Minted"
                            content={totalMintedAmount}
                            // content="0"
                        />
                        <Status
                            header="Scales Left in This Drop"
                            content={ scalesLeftInThisDrop+"/"+ newAddedAmount}
                        />
                        <Status
                            header="Scales Left to Drop"
                            // content="10,000"
                            content={scalesLeftOnDrop}
                        />
                    </div>
                </div>
                
        </div>
            
    )
}

export default Stats