import React from "react";
import "./index.css"

const SectionHeader = (props) => {
    return (
        <div className="section-header content">
            <div className="section-name">
                {props.section}
            </div>
            <div className="section-description">
                {props.description}
            </div>

        </div>
    )
}

export default SectionHeader