import React from "react";
import "./index.css";
import Button from "@mui/material/Button";

const MainHero = (props) => {

    return (
        <div className="main-hero">
            <div className="container">
                <div className="main-title">
                    PAINTINGS FOR THE PEOPLE
                </div>
                <div className="mint-button-group-wrapper">
                    <div className="mint-button-group">
                        <div className="mint-button-wrap">
                        <Button 
                            variant="contained"  
                            className="mint-button" 
                            style={{textTransform: 'capitalize'}}
                            onClick={props.onClickMintButton}
                        >
                            <span> Mint</span >&nbsp;ONE Scale
                        </Button> 
                        </div>
                        <div className="mint-button-wrap">
                        <Button 
                            variant="contained"  
                            className="mint-button" 
                            style={{textTransform: 'capitalize'}}
                            onClick={props.onClickMintbatchButton}
                        >
                            <span> Mint</span> &nbsp;TWO Scales
                        </Button> 
                        </div>
                    </div>
                </div>
                
                <div className="mint-info">
                    <div className="mint-info-content">
                        Scales are FREE to mint (gas only) - to apply for the allowlist, click <a href=" https://heymint.xyz/scalesdrop11">here</a>.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainHero