import React from "react";
import "./Status.css"

const Status = (props) => {
    return (
        <div className="status">
            <div className="status-header">
                {props.header}
            </div>
            <div className="status-content-wrap">
                <div className="status-content">
                    {props.content}
                </div>  
            </div>
                      
        </div>        
    )
}

export default Status